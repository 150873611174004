import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Flow, Button, Spinner, Alert, Section, Block, Row, Heading } from 'ui'
import useFetch from 'hooks/useFetch'
import { getBookingsUrl, deleteBooking } from 'requests/requests'
import { formatDateWithWeekdayAndTime } from 'utils/formatDate'
import LogoutButton from 'components/LogoutButton'

type Booking = {
  id: number
  first_name: string
  last_name: string
  national_id: string
  phone: string
  slot_id: number
  slot: {
    start_time: string
    end_time: string
    location: {
      title: string
      address?: []
    }
  }
}

type GetBookings = [Booking]

export default function MyPageAppointments() {
  const history = useHistory()

  const { data, error, isLoading } = useFetch<GetBookings>(getBookingsUrl())

  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  // Sort apointments by start date
  const preparedData = data?.sort(
    // @ts-expect-error
    (a, b) => new Date(a?.slot?.start_time) - new Date(b?.slot?.start_time),
  )

  const handleDeleteBooking = async ({
    slotId,
    bookingId,
  }: {
    slotId: string
    bookingId: string
  }) => {
    console.log('Delete bookings', slotId, bookingId)

    const response = await deleteBooking({ slotId, bookingId })

    if (response?.errors != null) {
      setErrorMessage(response?.message)
      window.scrollTo(0, 0)
      return
    }

    // Super basic way to refetch booking data after delete
    window.location.reload()
  }

  const handleChangeBooking = ({
    bookingId,
    slotId,
  }: {
    bookingId: string
    slotId: string
  }) => {
    // Simple way to store data between routes / pages
    sessionStorage.setItem(
      'oldBooking',
      JSON.stringify({
        slotId,
        bookingId,
      }),
    )

    history.push('/booking/velg-sted')
  }

  return (
    <>
      <Flow>
        <Heading size={600}>Min side</Heading>

        {isLoading && <Spinner />}

        {error && (
          <Alert>
            Noe gikk galt. Feilmelding:
            <br />
            <br />
            <code>{error?.message}</code>
          </Alert>
        )}

        {errorMessage && (
          <Alert>
            Noe gikk galt. Feilmelding:
            <br />
            <br />
            <code>{errorMessage}</code>
          </Alert>
        )}

        {data && data?.length < 1 && <p>Fant ingen aktive avtaler.</p>}

        {preparedData?.map(({ id, slot, slot_id: slotId }) => (
          <Section background="white" verticalPadding="medium" key={id}>
            <Block bottom="6">
              <Block bottom="3">
                <h4>{formatDateWithWeekdayAndTime(slot.start_time)}</h4>
              </Block>
              <p>
                {slot?.location?.title}
                <br />
                {slot?.location?.address?.join(', ')}
              </p>
            </Block>
            <Row>
              <Button
                variant="small"
                marginRight
                onClick={() => {
                  void handleChangeBooking({
                    bookingId: String(id),
                    slotId: String(slotId),
                  })
                }}
              >
                Endre
              </Button>
              <Button
                variant="small"
                danger
                isLoading={isLoading}
                isDisabled={isLoading}
                onClick={() => {
                  void handleDeleteBooking({
                    slotId: String(slotId),
                    bookingId: String(id),
                  })
                }}
              >
                Avbestill
              </Button>
            </Row>
          </Section>
        ))}

        <LogoutButton />
      </Flow>
    </>
  )
}
