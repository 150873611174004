import { clear } from './storage'

export default function clearSessionData() {
  // Leagacy measures to clean local storage
  localStorage.removeItem('roles')
  localStorage.removeItem('org_units')
  localStorage.removeItem('session')

  clear()
}
