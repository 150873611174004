import { HTMLProps, ReactNode } from 'react'
import classNames from 'classnames'

import styles from './Button.module.scss'

type LinkButtonProps = {
  children: ReactNode
  variant?: 'primary' | 'secondary' | 'small' | 'transparent'
  href: string
  fullWidth?: boolean
  marginRight?: boolean
  circular?: boolean
} & HTMLProps<HTMLAnchorElement>

export function LinkButton({
  children,
  variant = 'primary',
  type = 'button',
  href,
  fullWidth,
  onClick,
  circular = false,
  marginRight,
  className,
  ...rest
}: LinkButtonProps) {
  const buttonClass = classNames(
    styles.button,
    styles.linkButton,
    styles[`-${variant}`],
    circular ? styles['-circular'] : false,
    fullWidth ? styles['-full-width'] : false,
    marginRight ? styles['-margin-right'] : false,
    className,
  )

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <a href={href} className={buttonClass} {...rest}>
        {children}
      </a>
    </div>
  )
}
