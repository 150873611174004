@import "../scss/functions";

.title {
  margin-bottom: var(--space-3);
}

.wrap {
  margin-bottom: var(--space-4);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.label {
  display: inline-block;
  padding-left: rem(0.8);
  position: relative;
}

.error {
  margin-top: var(--space-2);
  color: var(--color-red);
}

.radio-error {
  &:before {
    border-color: var(--color-red);
  }
}

.description {
  color: var(--color-gray);
  margin-top: var(--space-2);
  font-size: 90%;
}
