.progress-bar {
  display: block;
  position: relative;
  overflow: hidden;
  height: 6px;
  width: 100%;
  background: var(--color-blue--light);
}

.value-bar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: var(--color-blue);
  transition: transform 350ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transform-origin: left;
}
