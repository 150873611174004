import { LinkButton } from 'ui'
import React from 'react'

type Props = {
  myPage?: boolean
}

export default function LoginButton({ myPage }: Props) {
  const generateAuthUrl = () => {
    const method = 'idporten'

    const returnPath = myPage ? '/min-side/avtaler' : '/booking/velg-sted'
    const returnUrl = `${String(process.env.REACT_APP_URL)}${returnPath}`

    return `${String(
      process.env.REACT_APP_GRAPHQL_GATEWAY,
    )}/auth/login?client=${method}&redirectUrl=${encodeURIComponent(
      returnUrl,
    )}`
  }

  return (
    <>
      <LinkButton href={generateAuthUrl()}>Logg inn</LinkButton>
    </>
  )
}
