@import "../scss/functions";

.alert {
  margin-top: 15px;
  padding: var(--space-5);
  border-radius: var(--border-radius);
  border: 1px solid var(--color-red);
}

.-error {
  background-color: var(--color-red);
  color: var(--color-light);
  transform-origin: center;
  animation: error-animation 400ms ease-in-out;
}

.-info {
  background-color: white;
  border-color: var(--color-yellow);
}

.-ok {
  background-color: white;
  border-color: var(--color-green);
}

.-neutral {
  background-color: white;
  border-color: var(--color-blue-gray);
}

@keyframes error-animation {
  0% {
    transform: translateX(0);
  }

  60% {
    transform: translateX(rem(-1));
  }

  70% {
    transform: translateX(rem(1));
  }

  90% {
    transform: translateX(rem(-1));
  }

  100% {
    transform: translateX(0);
  }
}
