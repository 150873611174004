import { HTMLProps, ReactNode } from 'react'
import classNames from 'classnames'

import styles from './Fieldset.module.scss'

export type Props = {
  children: ReactNode
} & HTMLProps<HTMLFieldSetElement>

export const Fieldset = ({ className, ...rest }: Props) => (
  <fieldset className={classNames(styles.fieldset, className)} {...rest} />
)
