import { Flow, LinkButton } from 'ui'

export default function SessionError() {
  return (
    <Flow>
      <h1>Noe gikk galt</h1>
      <p>
        Denne siden ble ikke vist riktig. Gå tilbake til forsiden og prøv igjen.
      </p>
      <LinkButton href="/">Til forsiden</LinkButton>
    </Flow>
  )
}
