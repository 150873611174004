import { createElement, HTMLProps, ReactNode } from 'react'
import classNames from 'classnames'

import styles from './Label.module.scss'

export type Props = {
  children: ReactNode
  is?: string
} & HTMLProps<HTMLLabelElement>

export const Label = ({ is = 'label', className, ...rest }: Props) => {
  return createElement(is, {
    className: classNames(styles.label, className),
    ...rest,
  })
}
