import './Modal.scss'

import React, { CSSProperties, ReactElement, ReactNode } from 'react'
import BEMHelper from 'react-bem-helper'
import { CloseIcon, Portal, Button } from 'ui'

import { Col, Row } from '../Layout'

const bem = new BEMHelper('modal')

export type Props = {
  children: ReactNode
  className?: string
  title?: string
  cancelTitle?: string
  confirmTitle?: string | ReactElement
  disabled?: boolean
  confirmLoading?: boolean
  mountNode?: HTMLElement | null
  scroll?: boolean
  show?: boolean
  padding?: boolean
  danger?: boolean
  style?: CSSProperties
  onClose?: () => void
  onCancel?: () => void
  onConfirm?: () => void
}

export const Modal = ({
  children,
  className,
  disabled,
  confirmTitle = 'Ok',
  cancelTitle = 'Avbryt',
  confirmLoading,
  show = true,
  title,
  mountNode,
  style,
  scroll = false,
  padding = true,
  danger = false,
  onClose,
  onCancel,
  onConfirm,
}: Props) => {
  return (
    <Portal show={show} fadeBackground mountNode={mountNode} onClickOutside={onClose} {...bem('')}>
      <Col
        style={style}
        {...bem('content', { padding }, className)}
        aria-modal="true"
        role="dialog"
      >
        <Row {...bem('top-bar')}>
          {title != null && (
            <Row center style={{ flex: '1 1 auto' }}>
              <h2 {...bem('title')}>{title}</h2>
            </Row>
          )}
          <Row {...bem('button-bar')}>
            {onClose && (
              <button type="button" {...bem('close')} aria-label="Avbryt" onClick={onClose}>
                <CloseIcon />
              </button>
            )}
          </Row>
        </Row>
        <Col style={{ flex: '1 1 auto', overflow: scroll ? 'auto' : undefined }}>{children}</Col>
        {(onConfirm != null || onCancel != null) && (
          <Row center {...bem('bottom-bar')}>
            {onCancel && (
              <Button
                variant="secondary"
                onClick={onCancel ?? onClose}
                style={{ margin: '0 15px' }}
              >
                {cancelTitle}
              </Button>
            )}
            {onConfirm && (
              <Button
                danger={danger}
                disabled={disabled}
                isLoading={confirmLoading}
                onClick={onConfirm}
              >
                {confirmTitle}
              </Button>
            )}
          </Row>
        )}
      </Col>
    </Portal>
  )
}

export type ModalContentProps = {
  children: ReactNode
}

const modalContentBem = new BEMHelper('modal-content')
export const ModalContent = ({ children }: ModalContentProps) => (
  <Col expand {...modalContentBem('')}>
    {children}
  </Col>
)
