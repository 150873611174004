import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import React from 'react'
import ReactDOM from 'react-dom'
import { ReminUI } from 'ui'
import AccessTokenWrapper from 'components/AccessToken'
import { ApolloWrapper } from 'webservice'

import App from './components/App'

ReactDOM.render(
  <React.StrictMode>
    <ReminUI>
      <ApolloWrapper>
        <ErrorBoundary>
          <AccessTokenWrapper authContext="booking">
            <App />
          </AccessTokenWrapper>
        </ErrorBoundary>
      </ApolloWrapper>
    </ReminUI>
  </React.StrictMode>,
  document.getElementById('root'),
)
