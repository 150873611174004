import { Flow, Alert, Block } from 'ui'
import SessionError from 'components/SessionError'
import LogoutButton from 'components/LogoutButton'

export default function ThankYou() {
  // Basic handle of missing session
  if (sessionStorage?.booking == null) {
    return <SessionError />
  }

  const { location, readableDate, address } = JSON.parse(
    sessionStorage.booking,
  )

  return (
    <>
      <Flow>
        <h1>Din time er bestilt!</h1>

        <p>
          Takk for at du vil bli blodgiver. Snart er det din tur til å hjelpe
          noen som trenger det!
        </p>

        <p>
          Når du kommer til blodbanken, vil du bli bedt om å fylle ut et
          spørreskjema. Deretter blir du intervjuet av en av våre ansatte.
          Etterpå tar vi en blodprøve av deg for å sjekke din blodtype og at du
          ikke er bærer av virus som kan smitte gjennom blod.
        </p>

        <p>
          Når informasjon om deg og dine prøver er godkjent, vil du få time til
          ordinær blodgivning. Merk at det kan være andre forhold som kommer
          fram ved intervju som står i veien for at du kan gi blod.
        </p>
      </Flow>

      <Block top="6" bottom="6">
        <Alert variant="ok">
          <Flow space="normal">
            <p>
              <strong>{location}</strong>
              <br />
              {address}
            </p>
            <p>
              <strong>{readableDate}</strong>
            </p>
          </Flow>
        </Alert>
      </Block>

      <LogoutButton />
    </>
  )
}
