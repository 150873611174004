import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** Date (isoformat) */
  Date: string
  /** DateOrDatetime scalar represents an ISO 8601 formatted string, and will be parsed as a date or datetime depending on if there is a time component in the string */
  DateOrDatetime: string
  /** Date with time (isoformat) */
  DateTime: string
  /** The JSONScalar scalar type represents a generic GraphQL scalar value that could be: List or Object. */
  JSONScalar: string
  UUID: string
}

export type AppointmentConfirmation = {
  __typename?: 'AppointmentConfirmation'
  adminComment?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  date: Scalars['DateOrDatetime']
  id: Scalars['ID']
  isShortNoticeOk?: Maybe<Scalars['Boolean']>
  location?: Maybe<Scalars['String']>
  phone: Scalars['String']
  processingStatus: AppointmentConfirmationProcessingStatus
  rejectionReason?: Maybe<Scalars['String']>
  sms?: Maybe<SmsNotification>
  ssn: Scalars['String']
  status: AppointmentConfirmationStatus
  updatedAt: Scalars['DateTime']
  wantsNewAppointment?: Maybe<Scalars['Boolean']>
}

export type AppointmentConfirmationContactInfo = {
  __typename?: 'AppointmentConfirmationContactInfo'
  firstName?: Maybe<Scalars['String']>
  fnr?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  middleName?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
}

export type AppointmentConfirmationExpiredAppointmentConfirmation = AppointmentConfirmation | ExpiredAppointmentConfirmation

export type AppointmentConfirmationProcessingStatus =
  | 'completed'
  | 'created'
  | 'responded'
  | 'sent'

export type AppointmentConfirmationResponse =
  | 'confirmed'
  | 'rejected'

export type AppointmentConfirmationStatus =
  | 'confirmed'
  | 'pending'
  | 'rejected'

export type CheckRefugeeTaskCredentialsResponse = MutationResponse & {
  __typename?: 'CheckRefugeeTaskCredentialsResponse'
  code: Scalars['String']
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
  task?: Maybe<RefugeeTask>
}

export type CreateAppointmentConfirmationData = {
  adminComment?: InputMaybe<Scalars['String']>
  date: Scalars['DateOrDatetime']
  location?: InputMaybe<Scalars['String']>
  phone: Scalars['String']
  ssn: Scalars['String']
}

export type CreateAppointmentConfirmationsResponse = MutationResponse & {
  __typename?: 'CreateAppointmentConfirmationsResponse'
  appointmentConfirmations: AppointmentConfirmation[]
  code: Scalars['String']
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type CreateQuestionnaireReminderOutcome = MutationResponse & {
  __typename?: 'CreateQuestionnaireReminderOutcome'
  code: Scalars['String']
  message?: Maybe<Scalars['String']>
  questionnaireReminder?: Maybe<QuestionnaireReminder>
  success: Scalars['Boolean']
}

export type CreateQuestionnaireResponseOutcome = MutationResponse & {
  __typename?: 'CreateQuestionnaireResponseOutcome'
  code: Scalars['String']
  message?: Maybe<Scalars['String']>
  questionnaireResponse?: Maybe<QuestionnaireResponse>
  success: Scalars['Boolean']
}

export type ExpiredAppointmentConfirmation = {
  __typename?: 'ExpiredAppointmentConfirmation'
  id: Scalars['ID']
}

export type Mutation = {
  __typename?: 'Mutation'
  addQuestionnaireReminder: CreateQuestionnaireReminderOutcome
  addQuestionnaireResponse: CreateQuestionnaireResponseOutcome
  checkRefugeeMedicalHistoryFormCredentials: CheckRefugeeTaskCredentialsResponse
  completeAppointmentConfirmation: UpdateAppointmentConfirmationResponse
  createAppointmentConfirmations: CreateAppointmentConfirmationsResponse
  regenerateRefugeeTaskToken: RegenerateRefugeeTaskTokenResponse
  startRefugeeCase: StartRefugeeCaseResponse
  startRefugeeTask: StartRefugeeTaskResponse
  submitRefugeeMedicalHistoryForm: SubmitRefugeeTaskResponse
  updateAppointmentConfirmation: UpdateAppointmentConfirmationResponse
  updateAppointmentConfirmationAsAdmin: UpdateAppointmentConfirmationResponse
}

export type MutationAddQuestionnaireReminderArgs = {
  appointmentDate: Scalars['Date']
  phone: Scalars['String']
}

export type MutationAddQuestionnaireResponseArgs = {
  data?: InputMaybe<Scalars['JSONScalar']>
  outcome: QuestionnaireResponseOutcome
  questionnaireVersion: Scalars['String']
}

export type MutationCheckRefugeeMedicalHistoryFormCredentialsArgs = {
  nationalId: Scalars['String']
  token: Scalars['String']
}

export type MutationCompleteAppointmentConfirmationArgs = {
  id: Scalars['ID']
}

export type MutationCreateAppointmentConfirmationsArgs = {
  appointmentConfirmations: CreateAppointmentConfirmationData[]
}

export type MutationRegenerateRefugeeTaskTokenArgs = {
  taskId: Scalars['Int']
}

export type MutationStartRefugeeCaseArgs = {
  driversLicenseNumber?: InputMaybe<Scalars['String']>
  firstName: Scalars['String']
  lastName: Scalars['String']
  nationalId: Scalars['String']
  nationality?: InputMaybe<Scalars['String']>
  passportNumber?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
}

export type MutationStartRefugeeTaskArgs = {
  taskId: Scalars['Int']
}

export type MutationSubmitRefugeeMedicalHistoryFormArgs = {
  data: Scalars['JSONScalar']
  nationalId: Scalars['String']
  token: Scalars['String']
}

export type MutationUpdateAppointmentConfirmationArgs = {
  data: UpdateAppointmentConfirmationData
  id: Scalars['ID']
}

export type MutationUpdateAppointmentConfirmationAsAdminArgs = {
  data: UpdateAppointmentConfirmationAsAdminData
  id: Scalars['ID']
}

export type MutationResponse = {
  code: Scalars['String']
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type Organization = {
  __typename?: 'Organization'
  id: Scalars['ID']
  identifiers: OrganizationIdentifier[]
}

export type OrganizationIdentifier = {
  __typename?: 'OrganizationIdentifier'
  country: Scalars['String']
  createdAt: Scalars['DateTime']
  id: Scalars['UUID']
  type: OrganizationIdentifierType
  updatedAt: Scalars['DateTime']
  value: Scalars['String']
}

export type OrganizationIdentifierType =
  | 'DHIS_ID'
  | 'MUNICIPALITY_NUMBER'
  | 'ORG_NAME'
  | 'ORG_NUMBER'

export type Query = {
  __typename?: 'Query'
  Service?: Maybe<Scalars['String']>
  /**
   * Get a JWT token to be used towards Remin services. Should be
   * sent with requests to the other service as a bearer token.
   *
   * The token is updated before it expires, and when using it you
   * should expect it to change. Fetch new ones by querying for it.
   */
  accessToken?: Maybe<Scalars['String']>
  getAppointmentConfirmation?: Maybe<AppointmentConfirmationExpiredAppointmentConfirmation>
  getAppointmentConfirmationContactInfo: AppointmentConfirmationContactInfo
  getAppointmentConfirmationSmsPreview: Scalars['String']
  getAppointmentConfirmations: AppointmentConfirmation[]
  /** Get a URL that can be used for authentication. */
  loginUrl: Scalars['String']
  /** Get a url for logging the user out of the application. */
  logoutUrl: Scalars['String']
  me?: Maybe<User>
  questionnaireReminders: QuestionnaireReminder[]
  questionnaireResponses: QuestionnaireResponse[]
  refugeeCase?: Maybe<RefugeeCase>
  refugeeCases: RefugeeCase[]
}

export type QueryGetAppointmentConfirmationArgs = {
  id: Scalars['ID']
}

export type QueryGetAppointmentConfirmationContactInfoArgs = {
  fnr: Scalars['String']
}

export type QueryGetAppointmentConfirmationSmsPreviewArgs = {
  appointmentDate: Scalars['DateOrDatetime']
  appointmentLocation?: InputMaybe<Scalars['String']>
}

export type QueryGetAppointmentConfirmationsArgs = {
  appointmentDate?: InputMaybe<Scalars['Date']>
  isShortNoticeOk?: InputMaybe<Scalars['Boolean']>
  limit?: Scalars['Int']
  page?: InputMaybe<Scalars['Int']>
  processingStatus?: InputMaybe<AppointmentConfirmationProcessingStatus[]>
  status?: InputMaybe<AppointmentConfirmationStatus[]>
  wantsNewAppointment?: InputMaybe<Scalars['Boolean']>
}

export type QueryLoginUrlArgs = {
  redirectUrl: Scalars['String']
}

export type QueryLogoutUrlArgs = {
  redirectUrl: Scalars['String']
}

export type QueryQuestionnaireResponsesArgs = {
  createdDate?: InputMaybe<Scalars['Date']>
  entityId?: InputMaybe<Scalars['UUID']>
}

export type QueryRefugeeCaseArgs = {
  id: Scalars['Int']
}

export type QueryRefugeeCasesArgs = {
  n?: Scalars['Int']
  page?: InputMaybe<Scalars['Int']>
  sortBy?: RefugeeCaseSortColumn
  sortDirection?: RefugeeSortDirection
}

export type QuestionnaireReminder = {
  __typename?: 'QuestionnaireReminder'
  appointmentDate: Scalars['Date']
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  notificationId?: Maybe<Scalars['Int']>
  notificationSentAt?: Maybe<Scalars['DateTime']>
  notificationStatus?: Maybe<Scalars['String']>
  phone: Scalars['String']
}

export type QuestionnaireResponse = {
  __typename?: 'QuestionnaireResponse'
  createdAt: Scalars['DateTime']
  data: Scalars['JSONScalar']
  entityId: Scalars['UUID']
  id: Scalars['ID']
  outcome: QuestionnaireResponseOutcome
  questionnaireVersion: Scalars['String']
}

export type QuestionnaireResponseOutcome =
  | 'CLEARED'
  | 'FOLLOW_UP'

export type RefugeeCase = {
  __typename?: 'RefugeeCase'
  createdAt: Scalars['DateTime']
  createdBy: Scalars['UUID']
  firstName: Scalars['String']
  id: Scalars['Int']
  lastName: Scalars['String']
  municipalityNumber: Scalars['Int']
  nationalId: Scalars['String']
  phone?: Maybe<Scalars['String']>
  tasks: RefugeeTask[]
}

export type RefugeeCaseSortColumn =
  | 'createdAt'
  | 'firstName'
  | 'id'
  | 'lastName'
  | 'nationalId'
  | 'updatedAt'

export type RefugeeSortDirection =
  | 'ascending'
  | 'descending'

export type RefugeeTask = {
  __typename?: 'RefugeeTask'
  case?: Maybe<RefugeeCase>
  createdAt: Scalars['DateTime']
  data?: Maybe<Scalars['JSONScalar']>
  id: Scalars['Int']
  status: RefugeeTaskStatus
  token?: Maybe<Scalars['String']>
  tokenExpires?: Maybe<Scalars['DateTime']>
  type: RefugeeTaskType
}

export type RefugeeTaskStatus =
  | 'CANCELLED'
  | 'COMPLETED'
  | 'CREATED'
  | 'IN_PROGRESS'
  | 'STARTED'
  | 'SUBMITTED'

export type RefugeeTaskType =
  | 'MEDICAL_HISTORY_FORM'

export type RegenerateRefugeeTaskTokenResponse = MutationResponse & {
  __typename?: 'RegenerateRefugeeTaskTokenResponse'
  code: Scalars['String']
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
  task?: Maybe<RefugeeTask>
}

export type SmsNotification = {
  __typename?: 'SmsNotification'
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  sentAt?: Maybe<Scalars['DateTime']>
  status: SmsStatus
}

export type SmsStatus =
  | 'failed'
  | 'ok'
  | 'queued'
  | 'unknown'

export type StartRefugeeCaseResponse = MutationResponse & {
  __typename?: 'StartRefugeeCaseResponse'
  case?: Maybe<RefugeeCase>
  code: Scalars['String']
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type StartRefugeeTaskResponse = MutationResponse & {
  __typename?: 'StartRefugeeTaskResponse'
  code: Scalars['String']
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
  task?: Maybe<RefugeeTask>
}

export type SubmitRefugeeTaskResponse = MutationResponse & {
  __typename?: 'SubmitRefugeeTaskResponse'
  code: Scalars['String']
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
  task?: Maybe<RefugeeTask>
}

export type UpdateAppointmentConfirmationAsAdminData = {
  adminComment?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
  status?: InputMaybe<AppointmentConfirmationResponse>
}

export type UpdateAppointmentConfirmationData = {
  isShortNoticeOk?: InputMaybe<Scalars['Boolean']>
  rejectionReason?: InputMaybe<Scalars['String']>
  status: AppointmentConfirmationResponse
  wantsNewAppointment?: InputMaybe<Scalars['Boolean']>
}

export type UpdateAppointmentConfirmationResponse = MutationResponse & {
  __typename?: 'UpdateAppointmentConfirmationResponse'
  appointmentConfirmation?: Maybe<AppointmentConfirmation>
  code: Scalars['String']
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type User = {
  __typename?: 'User'
  firstName: Scalars['String']
  id: Scalars['ID']
  lastName: Scalars['String']
  ousNoshowAdmin: Scalars['Boolean']
  refugeeMunicipality?: Maybe<Scalars['Int']>
}

export type GetMeQueryVariables = Exact<{ [key: string]: never }>

export type GetMeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, firstName: string, lastName: string } | null }

export type GetAccessTokenQueryVariables = Exact<{ [key: string]: never }>

export type GetAccessTokenQuery = { __typename?: 'Query', accessToken?: string | null }

export const GetMeDocument = gql`
    query GetMe {
  me {
    id
    firstName
    lastName
  }
}
    `

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        const options = { ...defaultOptions, ...baseOptions }
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options)
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = { ...defaultOptions, ...baseOptions }
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options)
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>
export const GetAccessTokenDocument = gql`
    query GetAccessToken {
  accessToken
}
    `

/**
 * __useGetAccessTokenQuery__
 *
 * To run a query within a React component, call `useGetAccessTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccessTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccessTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccessTokenQuery(baseOptions?: Apollo.QueryHookOptions<GetAccessTokenQuery, GetAccessTokenQueryVariables>) {
        const options = { ...defaultOptions, ...baseOptions }
        return Apollo.useQuery<GetAccessTokenQuery, GetAccessTokenQueryVariables>(GetAccessTokenDocument, options)
      }
export function useGetAccessTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccessTokenQuery, GetAccessTokenQueryVariables>) {
          const options = { ...defaultOptions, ...baseOptions }
          return Apollo.useLazyQuery<GetAccessTokenQuery, GetAccessTokenQueryVariables>(GetAccessTokenDocument, options)
        }
export type GetAccessTokenQueryHookResult = ReturnType<typeof useGetAccessTokenQuery>
export type GetAccessTokenLazyQueryHookResult = ReturnType<typeof useGetAccessTokenLazyQuery>
export type GetAccessTokenQueryResult = Apollo.QueryResult<GetAccessTokenQuery, GetAccessTokenQueryVariables>
