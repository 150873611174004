import { Flow, LinkButton } from 'ui'

export default function NotSignedIn() {
  return (
    <>
      <Flow>
        <h1>Du er ikke innlogget</h1>
        <p>For å se denne siden må du logge inn.</p>
        <LinkButton href="/">Gå til forsiden</LinkButton>
      </Flow>
    </>
  )
}
