import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'

// Scroll to top on navigation change
export default function ScrollToTop() {
  const history = useHistory()
  useEffect(() => {
    const unlisten = history.listen((location: any, action: any) => {
      if (action === 'POP') {
        // Keep original position if navigating backwards
        return
      }
      window.scrollTo(0, 0)
    })
    return () => unlisten()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return null
}
