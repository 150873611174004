import { Flow, Alert, Button } from 'ui'
import LoginButton from 'components/LoginButton'
import { useHistory } from 'react-router-dom'

export type Props = {
  isSignedIn: boolean
}

export function ResultAccept({ isSignedIn }: Props) {
  const history = useHistory()
  return (
    <Alert variant="ok">
      <Flow space="normal">
        <p>Velkommen til Blodbanken!</p>
        <p>
          For å starte som blodgiver, må du avtale et førstegangsintervju og
          avlegge en blodprøve hos oss.
        </p>
        <p>
          Logg inn med BankID for å velge en dag og et tidspunkt som passer for
          deg.
        </p>
        {isSignedIn ? (
          <Button
            onClick={() => {
              history.push('/booking/velg-sted')
            }}
          >
            Gå videre
          </Button>
        ) : (
          <LoginButton />
        )}
      </Flow>
    </Alert>
  )
}
