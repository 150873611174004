import classNames from 'classnames'

import styles from './Flow.module.scss'

type Props = {
  children: React.ReactNode
  space?: 'normal' | 'large'
}

export function Flow({ children, space = 'large', ...rest }: Props) {
  const flowClass = classNames(styles.flow, styles[`-${space}`])

  return (
    <section className={flowClass} {...rest}>
      {children}
    </section>
  )
}
