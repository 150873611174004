import { Flow } from 'ui'

export default function Error404() {
  return (
    <>
      <Flow>
        <h1>Denne siden finnes ikke...</h1>
        <p>Beklager, denne siden fant vi ikke.</p>
      </Flow>
    </>
  )
}
