import { PropsWithChildren } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { Col, Section } from 'ui'
import Header from 'components/Header'
import Welcome from 'pages/Welcome'
import Selftest from 'pages/Selftest'
import FollowUpThankYou from 'pages/FollowUpThankYou'
import BookingLocation from 'pages/BookingLocation'
import BookingConfirmation from 'pages/BookingConfirmation'
import BookingThankYou from 'pages/BookingThankYou'
import MyPage from 'pages/MyPage'
import MyPageAppointments from 'pages/MyPageAppointments'
import Error404 from 'pages/Error404'
import NotSignedIn from 'pages/NotSignedIn'
import ScrollToTop from 'utils/ScrollToTop'
import useSession from 'hooks/useSession'
import useSystemStatusMessage from 'hooks/useSystemStatusMessage'

import styles from './App.module.scss'

export default function App() {
  const { isLoading, isSignedIn, logout } = useSession()
  const SelfTestComponent = () => <Selftest isSignedIn={isSignedIn} />

  return (
    <ApplicationDown>
      <div className={styles.app}>
        <Header isSignedIn={isSignedIn} logout={logout} />
        <Section width="small" verticalPadding="medium">
          <Router>
            <ScrollToTop />
            <Switch>
              <Route exact path="/" component={Welcome} />
              <Route exact path="/selvtest" component={SelfTestComponent} />
              <Route exact path="/selvtest/takk" component={FollowUpThankYou} />
              <Route exact path="/loggedin">
                <Redirect to={'/booking/velg-sted'} />
              </Route>
              <Route exact path="/ikke-logget-inn" component={NotSignedIn} />
              {!isSignedIn && (
                <Route exact path="/min-side" component={MyPage} />
              )}
              <Route
                exact
                path="/min-side/avtaler"
                component={MyPageAppointments}
              />
              {isLoading || isSignedIn ? (
                <>
                  <Route
                    exact
                    path="/booking/velg-sted"
                    component={BookingLocation}
                  />
                  <Route
                    exact
                    path="/booking/bekreft"
                    component={BookingConfirmation}
                  />
                  <Route
                    exact
                    path="/booking/takk"
                    component={BookingThankYou}
                  />

                  <Route exact path="/min-side" component={MyPage}>
                    <Redirect to={'/min-side/avtaler'} />
                  </Route>
                </>
              ) : (
                <Route path="/booking">
                  <Redirect to="/ikke-logget-inn" />
                </Route>
              )}
              <Route path="*" component={Error404} />
            </Switch>
          </Router>
        </Section>
      </div>
    </ApplicationDown>
  )
}

const ApplicationDown = ({ children }: PropsWithChildren<{}>) => {
  const { applicationUnavailable, tracerSystemMessage } =
    useSystemStatusMessage()
  if (applicationUnavailable) {
    return (
      <div
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          backgroundColor: '#f4d157',
          zIndex: 10000,
          padding: '40px',
          overflow: 'hidden',
        }}
      >
        <Col center style={{ gap: '16px' }}>
          <svg width="24px" height="24px" viewBox="0 0 24 24">
            <g>
              <path d="M12,0C5.37,0,0,5.37,0,12c0,6.63,5.37,12,12,12s12-5.37,12-12C24,5.37,18.63,0,12,0z M13.67,19.17 c-0.38,0.38-0.89,0.56-1.54,0.56c-0.7,0-1.26-0.16-1.69-0.47C10,18.95,9.79,18.49,9.79,17.9c0-0.64,0.19-1.14,0.56-1.5 c0.38-0.36,0.89-0.54,1.54-0.54c0.71,0,1.28,0.15,1.7,0.45c0.43,0.3,0.64,0.75,0.64,1.36C14.24,18.29,14.05,18.79,13.67,19.17z M14.08,9.25c-0.4,1.87-0.82,3.44-1.25,4.71c-0.16,0.01-0.4,0.02-0.72,0.02s-0.57-0.01-0.72-0.02c-0.47-1.35-0.93-2.95-1.38-4.81 S9.28,5.82,9.21,4.74c1.86-0.17,3.72-0.17,5.58,0C14.72,5.87,14.48,7.38,14.08,9.25z"></path>
            </g>
          </svg>
          <strong>{tracerSystemMessage}</strong>
        </Col>
      </div>
    )
  }
  return <>{children}</>
}
