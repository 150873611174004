import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Flow, Alert, Input, Button, Block } from 'ui'
import { postBooking, patchBooking } from 'requests/requests'
import SessionError from 'components/SessionError'

export default function BookingConfirmation() {
  const history = useHistory()

  const [errorMessage, setErrorMessage] = useState<any>(null)

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm()

  // Basic handle of missing session
  if (sessionStorage?.booking == null) {
    return <SessionError />
  }

  const { location, readableDate, slotId, address } = JSON.parse(
    sessionStorage.booking,
  )

  // If changing a previous booking (from "My page")
  let oldSlotId: string | null = null
  let oldBookingId: string | null = null
  if (sessionStorage?.oldBooking != null) {
    const oldBooking = JSON?.parse(sessionStorage.oldBooking)
    oldSlotId = oldBooking.slotId
    oldBookingId = oldBooking.bookingId
  }

  const onClickSubmit = async (data: any) => {
    const { phone } = data

    // Patch booking if old booking is set
    const response =
      oldSlotId != null && oldBookingId != null
        ? await patchBooking({ oldSlotId, oldBookingId, slotId })
        : await postBooking({ slotId, phone })

    if (response?.errors != null) {
      setErrorMessage(response.message)
      return
    }

    history.push('/booking/takk')
  }

  return (
    <>
      <h1>Bekreft din time</h1>

      <Block top="6" bottom="6">
        <Alert variant="neutral">
          <Flow space="normal">
            <p>
              <strong>{location}</strong>
              <br />
              {address}
            </p>
            <p>
              <strong>{readableDate}</strong>
            </p>
          </Flow>
        </Alert>
      </Block>

      <Flow space="normal">
        <form onSubmit={handleSubmit(onClickSubmit)}>
          <Flow>
            {/* We already have a users phone if they're changing a booking */}
            {oldSlotId == null && (
              <Input
                type="tel"
                name="phone"
                label="Ditt mobilnummer"
                description="8 siffer, for eksempel: 91234567"
                maxLength={8}
                register={register('phone', {
                  required: 'Skriv inn ditt mobilnummer, 8 siffer',
                  minLength: {
                    value: 8,
                    message: 'Skriv inn ditt mobilnummer, 8 siffer',
                  },
                })}
                errorMessage={errors?.phone?.message}
              />
            )}

            {errorMessage != null && (
              <Alert variant="error">
                <p>
                  Noe gikk galt. Feilmelding:
                  <br />
                  <code>{errorMessage}</code>
                </p>
              </Alert>
            )}

            <p>Vi sender deg en SMS med bekreftelse på bestilt time.</p>

            <Button
              type="submit"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Bestill time
            </Button>
          </Flow>
        </form>
      </Flow>
    </>
  )
}
