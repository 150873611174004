.spinner {
  color: var(--color-blue--dark);
  display: inline-block;
}

.svg {
  display: block;
  margin: 0;
  fill: none;
  stroke: currentColor;
  stroke-dashoffset: 100;
  will-change: transform;
  stroke-linecap: round;
  animation: spinner-rotating 800ms linear infinite;
}

.line {
  position: relative;
}

.line-muted {
  color: var(--color-blue);
  stroke-dasharray: 0;
}

@keyframes spinner-rotating {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}
