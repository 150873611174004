import { HTMLProps, ReactNode } from 'react'
import classNames from 'classnames'

import styles from './Button.module.scss'
import { Row, Spinner } from '..'

type ButtonProps = {
  children: ReactNode
  isDisabled?: boolean
  isLoading?: boolean
  variant?: 'primary' | 'secondary' | 'small' | 'transparent'
  type?: 'button' | 'submit'
  fullWidth?: boolean
  disabled?: boolean
  marginRight?: boolean
  circular?: boolean
  danger?: boolean
  onClick?: () => void
} & HTMLProps<HTMLButtonElement>

export function Button({
  children,
  isDisabled,
  isLoading,
  variant = 'primary',
  type = 'button',
  fullWidth,
  onClick,
  circular = false,
  marginRight,
  danger,
  className,
  ...rest
}: ButtonProps) {
  const buttonClass = classNames(
    styles.button,
    styles[`-${variant}`],
    circular ? styles['-circular'] : false,
    fullWidth ? styles['-full-width'] : false,
    danger ? styles['-danger'] : false,
    marginRight || isLoading ? styles['-margin-right'] : false,
    className,
  )

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <button
        className={buttonClass}
        disabled={isDisabled || isLoading}
        onClick={onClick}
        type={type}
        {...rest}
      >
        <Row center style={{ width: '100%', justifyContent: 'center' }}>
          {children}
          {isLoading && <Spinner width={20} style={{ marginLeft: '25px', marginRight: '-25px' }} />}
        </Row>
      </button>
    </div>
  )
}
