// eslint-disable-next-line
type Question = {
  key: string
  label: string
  /* Determines which answer will reject the premise ex. rejection: 'No' - if you answer 'No' we will show a warning */
  rejection: 'No' | 'Yes'
  /* default: false
  Determines whether the person ends up on a calling list, or is immediately declined from
  becoming a donor.
  */
  decline?: boolean
  /* The warning that appears if the question is rejected */
  warning?: string
}

export const questions = [
  {
    key: 'age' as const,
    label: 'Er du mellom 18-65 år?',
    decline: true,
    rejection: 'No',
    warning: 'Du må være mellom 18-65 år for å være blodgiver',
  },
  {
    key: 'language' as const,
    label: 'Behersker du norsk eller engelsk skriftlig og muntlig?',
    decline: true,
    rejection: 'No',
    warning: 'Du må beherske norsk eller engelsk skriftlig og muntlig for å være blodgiver.',
  },
  {
    key: 'underweight' as const,
    label: 'Veier du under 50 kg?',
    decline: true,
    rejection: 'Yes',
    warning: 'Du må veie over 50 kg for å være blodgiver.',
  },
  {
    key: 'piercing' as const,
    label: 'Har du piercing i nese eller andre slimhinner?',
    decline: true,
    rejection: 'Yes',
    warning:
      'Du kan ikke være blodgiver dersom du har piercing i slimhinner. Dersom piercingen fjernes kan du gi blod etter 1 måned.',
  },
  {
    key: 'seriousDisease' as const,
    label: 'Har du, eller har du hatt alvorlig eller kronisk sykdom?',
    rejection: 'Yes',
    warning:
      'Enkelte sykdommer utelukker deg fra å bli blodgiver, andre gir karantenetid etter at du blir frisk.',
  },
  {
    key: 'usesMedicine' as const,
    label: 'Bruker du faste medisiner (utenom p-piller og antihistaminer)?',
    rejection: 'Yes',
    warning:
      'Enkelte medikamenter utelukker deg fra å være blodgiver, andre gir karantene etter bruk. ',
  },
  {
    key: 'severeAllergicReaction' as const,
    label:
      'Har du noen gang hatt en alvorlig allergisk reaksjon som har blitt behandlet med adrenalin?',
    rejection: 'Yes',
    warning:
      'Du kan ikke gi blod dersom du har hatt alvorlig, livstruende allergisk reaksjon med pusteproblemer og behov for intensivbehandling, adrenalin eller liknende.',
  },
  {
    key: 'livedInAfrica' as const,
    label:
      'Har du oppholdt deg i Afrika sør for Sahara mer enn 5 år til sammen?',
    decline: true,
    rejection: 'Yes',
    warning:
      'Du ikke kan være blodgiver dersom du har oppholdt deg over 5 år til sammen i Afrika sør for Sahara.',
  },
  {
    key: 'livedInBritain' as const,
    label:
      'Har du oppholdt deg i Storbritannia i mer enn 1 år til sammen i perioden fra 1980-1996?',
    decline: true,
    rejection: 'Yes',
    warning:
      'Du kan ikke være blodgiver dersom du har oppholdt deg over 1 år til sammen i Storbritannia i perioden fra 1980 til 1996, på grunn av fare for variant Creutzfeldt-Jakob sykdom.',
  },
]
