import React, { forwardRef } from 'react'
import classNames from 'classnames'

import styles from './Layout.module.scss'

export type RowProps = React.HTMLAttributes<HTMLDivElement> & {
  children?: React.ReactNode
  center?: boolean
  expand?: boolean
  reverse?: boolean
  /** Turns into a column on phone displays */
  colOnPhone?: boolean
}

export const Row = forwardRef<HTMLDivElement, RowProps>(function Row(
  {
    children,
    center = false,
    className,
    expand = false,
    colOnPhone = false,
    reverse = false,
    ...props
  }: RowProps,
  ref,
) {
  return (
    <div
      ref={ref}
      className={classNames(
        styles.row,
        {
          [styles['-center']]: center,
          [styles['-expand']]: expand,
          [styles['-col-on-phone']]: colOnPhone,
          [styles['-row-reverse']]: reverse,
        },
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
})

export type ColProps = React.HTMLAttributes<HTMLDivElement> & {
  children?: React.ReactNode
  center?: boolean
  expand?: boolean
}

export const Col = forwardRef<HTMLDivElement, ColProps>(function Col(
  { children, center = false, expand = false, className, ...props },
  ref,
) {
  return (
    <div
      ref={ref}
      className={classNames(
        styles.col,
        {
          [styles['-center']]: center,
          [styles['-expand']]: expand,
        },
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
})
