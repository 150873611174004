import { getAccessToken, getAuthContext } from 'components/AccessToken'

export default function getAuthRequestHeaders() {
  console.log({
    ...(getAuthContext()
      ? {
          'X-Remin-State': getAuthContext(),
        }
      : {}),
    ...(getAccessToken()
      ? {
          Authorization: `Bearer ${getAccessToken()}`,
        }
      : {}),
  })

  return {
    ...(getAuthContext()
      ? {
          'X-Remin-State': getAuthContext(),
        }
      : {}),
    ...(getAccessToken()
      ? {
          Authorization: `Bearer ${getAccessToken()}`,
        }
      : {}),
  }
}
