import { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Flow, RadioGroup, Alert, Heading } from 'ui'
import { questions } from 'questions/questions'
import calculateResult from 'components/Selftest/calculateResult'

export type Props = {
  isSignedIn: boolean
}

export default function Selftest({ isSignedIn = false }: Props) {
  const {
    register,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: questions.reduce((total, { key }) => {
      return {
        ...total,
        [key]: null,
      }
    }, {}) as Record<typeof questions[0]['key'], string>,
  })

  const answers = watch()

  // Fix browser inconsistencies on navigate back
  // This causes the radio buttons to remember it's values (in Chrome), but it's not visible to React Hook Form
  // The form looks filled, but the logic is not working since the values are empty
  const formRef = useRef<any>()
  useEffect(() => {
    setTimeout(() => {
      formRef.current?.reset()
    }, 250)
  }, [])

  return (
    <>
      <Flow>
        <Heading size={600}>Selvtest</Heading>

        <form ref={formRef}>
          <Flow>
            {questions.map(({ key, label, warning, rejection }) => (
              <div key={key}>
                <RadioGroup
                  key={key}
                  name={key}
                  title={label}
                  register={register(key, {
                    required: 'Obligatorisk felt',
                  })}
                  options={[
                    {
                      value: rejection === 'Yes' ? 'false' : 'true',
                      label: 'Nei',
                    },
                    {
                      value: rejection === 'Yes' ? 'true' : 'false',
                      label: 'Ja',
                    },
                  ]}
                  errorMessage={errors?.[key]?.message}
                />
                {answers?.[key] === 'true' && (
                  <Alert variant="info">
                    <p>{warning}</p>
                  </Alert>
                )}
              </div>
            ))}
          </Flow>
        </form>
        <Heading size={500} style={{ marginTop: '25px' }}>
          Resultat
        </Heading>

        {calculateResult(answers, isSignedIn)}
      </Flow>
    </>
  )
}
