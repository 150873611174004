import classNames from 'classnames'
import { createElement } from 'react'

import styles from './Heading.module.scss'

export type Props = {
  secondary?: boolean
  size?: 900 | 800 | 700 | 500 | 600 | 400 | 300 | 200 | 100
  is?: 'h1' | 'h2' | 'h3' | 'h4'
  ellipsis?: boolean
  noMargin?: boolean
} & React.HTMLAttributes<HTMLHeadingElement>

export const Heading = ({
  size = 400,
  secondary = false,
  is = 'h2',
  noMargin = false,
  ellipsis = false,
  children,
  ...props
}: Props) => {
  return createElement(
    is,
    {
      className: classNames(styles.heading, {
        [styles[`-size-${size}`]]: true,
        [styles['-secondary']]: secondary,
        [styles['-ellipsis']]: ellipsis,
        'no-margin': noMargin,
      }),
      ...props,
    },
    children,
  )
}
