import { addMonths } from 'date-fns'
import { format } from 'date-fns-tz'
import getAuthRequestHeaders from 'lib/getAuthRequestHeaders'

const API_URL = process.env.REACT_APP_API_URL ?? ''
const BLODBANKEN_OSLO_OU = 'fWYaZ2Oaaun'
// const DISEASE_ID = 'H8eyuhvDxtX'

export async function getMe() {
  const response = await fetch(`${API_URL}me`, {
    credentials: 'include',
    headers: getAuthRequestHeaders(),
  })
  const result = await response.json()

  return {
    ...result,

    // temp fix for altered backend response structure
    name: result.first_name,
    family_name: result.last_name,
  }
}

export const getAvailabilityUrl = ({ months = 4 } = {}) => {
  const startDate = format(new Date(), 'yyyy-MM-dd', {
    timeZone: 'Europe/Oslo',
  })

  const endDate = format(addMonths(new Date(), months), 'yyyy-MM-dd', {
    timeZone: 'Europe/Oslo',
  })

  const url = `${API_URL}booking/availability?start_date=${startDate}&end_date=${endDate}&type=BLOOD_DONATION_SCREENING&org_unit_slug=blodbanken_oslo`
  return url
}

export const getBookingsUrl = () => {
  const url = `${API_URL}booking/bookings?type=BLOOD_DONATION_SCREENING&org_unit_slug=blodbanken_oslo`
  return url
}

export const postBooking = async ({
  slotId,
  phone,
}: {
  slotId: string
  phone: string
}) => {
  const url = `${API_URL}booking/slots/${slotId}/bookings`

  try {
    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        ...getAuthRequestHeaders(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phone,
        details: {},
      }),
    })
    const result = await response.json()
    return result
  } catch (e: any) {
    return { error: { ...e?.response, message: e?.response?.message } }
  }
}

export const deleteBooking = async ({
  slotId,
  bookingId,
}: {
  slotId: string
  bookingId: string
}) => {
  const url = `${API_URL}booking/slots/${slotId}/bookings/${bookingId}`

  try {
    const response = await fetch(url, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        ...getAuthRequestHeaders(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    const result = await response.json()
    return result
  } catch (e: any) {
    return { error: { ...e?.response, message: e?.response?.message } }
  }
}

export const patchBooking = async ({
  oldSlotId,
  oldBookingId,
  slotId,
}: {
  oldSlotId: string
  oldBookingId: string
  slotId: string
}) => {
  const url = `${API_URL}booking/slots/${oldSlotId}/bookings/${oldBookingId}`

  try {
    const response = await fetch(url, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        ...getAuthRequestHeaders(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        slot_id: slotId,
      }),
    })
    const result = await response.json()
    return result
  } catch (e: any) {
    return { error: { ...e?.response, message: e?.response?.message } }
  }
}

export const postWorkItem = async ({
  phone,
  firstName,
  lastName,
}: {
  phone: string
  firstName: string
  lastName: string
}) => {
  const url = `${API_URL}ou/${BLODBANKEN_OSLO_OU}/blood-donation-screening-work-items`

  try {
    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        ...getAuthRequestHeaders(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phone,
        first_name: firstName,
        last_name: lastName,
      }),
    })
    const result = await response.json()
    return result
  } catch (e: any) {
    return { error: { ...e?.response, message: e?.response?.message } }
  }
}
