import { Component, ErrorInfo, ReactNode } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import ErrorBoundaryPage from './ErrorBoundaryPage'

type Props = RouteComponentProps & {
  children: ReactNode
  location: {
    pathname: string
  }
}

type State = {
  hasError: boolean
  errorMessage: string
  errorInfo: string
}

class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
    errorMessage: '',
    errorInfo: '',
  }

  // Update state so the next render will show the fallback UI
  static getDerivedStateFromError() {
    return {
      hasError: true,
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      errorMessage: error?.message?.toString(),
      errorInfo: errorInfo?.componentStack?.toString(),
    })
    // + Logging etc.
  }

  // Reset error status on page navigation
  // Note: This currently doesn't work since we don't use routes
  componentDidUpdate(prevProps: Props) {
    if (prevProps.location?.pathname !== this.props.location?.pathname) {
      this.setState({ hasError: false })
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorBoundaryPage
          errorMessage={this.state.errorMessage}
          errorInfo={this.state.errorInfo}
        />
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
