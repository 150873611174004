import classNames from 'classnames'
import { ReactNode } from 'react'

import { Col, Row } from '..'
import styles from './Alert.module.scss'

type Props = {
  children?: ReactNode
  className?: string
  variant?: 'error' | 'info' | 'ok' | 'neutral'
}

export function Alert({ variant = 'error', className, children }: Props) {
  const _className = classNames(styles.alert, styles[`-${variant}`], className)

  return (
    <Row className={_className}>
      <Col>{children}</Col>
    </Row>
  )
}
