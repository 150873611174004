import '../scss/main.scss'
import classNames from 'classnames'
import { createContext, HTMLAttributes, ReactNode, useContext, useMemo, useState } from 'react'

/**
 * Our UI Library is used in componetns that are included in
 * other websites. Due to this we can't use global resets or themes.
 * Make sure to include these classes in the top level element on the
 * relevant component/page.
 */
export const classes = {
  reset: 'remin-reset',
  theme: 'remin-theme',
}

export const ReminUI = ({ children, className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  const { userThemes } = useContext(ReminUIContext)

  return (
    <div className={classNames(classes.reset, classes.theme, userThemes, className)} {...props}>
      {children}
    </div>
  )
}

export const ReminThemeContext = ({ children }: { children: ReactNode }) => {
  const [userThemes, setUserThemes] = useState<string[]>([])

  const value = useMemo(() => ({ userThemes, setUserThemes }), [userThemes, setUserThemes])
  return <ReminUIContext.Provider value={value}>{children}</ReminUIContext.Provider>
}

export type ReminUIContextProps = {
  userThemes: string[]
  setUserThemes: (classNames: string[]) => void
}

export const ReminUIContext = createContext<ReminUIContextProps>({
  userThemes: [],
  setUserThemes: () => {},
})
