// Default flow
// https://24ways.org/2018/managing-flow-and-rhythm-with-css-custom-properties/

.flow {
  display: block;
}

// Modifiers
.-normal > * + * {
  margin-top: 1em;
}

.-large > * + * {
  margin-top: 2em;
}
