import {
  useEffect,
  useState,
  useCallback,
  createContext,
  ReactNode,
} from 'react'

declare const process: {
  env: { [index: string]: string }
}

const currentAPIDomain = process.env.REACT_APP_API_URL ?? ''
const authReturnUrl = process.env.REACT_APP_AUTH_RETURN_URL ?? ''

const pollForSessionKeepAlive = async () => {
  const response = await fetch(`${currentAPIDomain}me/keepalive`, {
    credentials: 'include',
  })
  // Let's assume anything below 300 means we are logged in

  if (response.status >= 300) {
    throw new Error()
  }
}

const getMe = async () => {
  const response = await fetch(`${currentAPIDomain}me`, {
    credentials: 'include',
  })

  // Let's assume anything other than 300
  // means we are logged out

  if (response.status > 300) {
    throw new Error()
  }
  return await response.json()
}

const setSessionState = (state: SessionState | null) => {
  if (state == null) {
    sessionStorage.removeItem('session_state')
  } else {
    sessionStorage.setItem('session_state', JSON.stringify(state))
  }
}

const getSessionState = () => {
  try {
    return JSON.parse(sessionStorage.get('session_state')) as SessionState
  } catch (e) {
    return null
  }
}

const useSession = () => {
  const [isSignedIn, setIsSignedIn] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [shouldBeLoggedOut, setShouldBeLoggedOut] = useState(false)
  const [state, setState] = useState<SessionState | null>(getSessionState())
  // Polling starts after /me check
  const [shouldBePolling, setShouldBePolling] = useState(false)

  useEffect(() => {
    setSessionState(state)
  }, [state])

  useEffect(() => {
    if (isSignedIn && !shouldBeLoggedOut) {
      setShouldBeLoggedOut(true)
    }

    if (!isSignedIn && shouldBeLoggedOut) {
      clearSession()
    }
  }, [isSignedIn, isLoading, shouldBeLoggedOut])

  /** Checks whether the user is logged in or not.
   * If the user has logged in elsewhere, we call the /me endpoint to get
   * the session state.
   */
  const poll = useCallback(async () => {
    try {
      await pollForSessionKeepAlive()

      // If we're not already signed in, set the signed in state
      if (!isSignedIn) {
        const response = await getMe()
        setState(response.state)
        setIsSignedIn(true)
      }
    } catch (e) {
      // If the polling fails, log the user out
      setIsSignedIn(false)
    }
  }, [isSignedIn])

  // Handles the polling state
  useEffect(() => {
    let interval: any
    if (shouldBePolling) {
      interval = setInterval(() => void poll(), 2 * 60 * 1000)
    }

    return () => clearInterval(interval)
  }, [shouldBePolling, poll])

  /** Check /me first to check if we are logged in, then poll /keepalive after */
  useEffect(() => {
    const checkIfLoggedIn = async () => {
      try {
        const response = await getMe()
        setState(response.state)
        setIsSignedIn(true)
      } catch (e) {
        setIsSignedIn(false)
      } finally {
        setShouldBePolling(true)
        setIsLoading(false)
      }
    }

    void checkIfLoggedIn()
  }, [])

  return {
    isLoading,
    isSignedIn,
    state,
    setState,
  }
}

/** Returns a url for the authentication redirect
 * 'helseid' is used for healthpersonell login
 * 'idporten' is used for citizen login
 */
export function getAuthUrl(
  state: string,
  method: 'helseid' | 'idporten' = 'helseid',
  returnUrl: string = '',
) {
  return `${currentAPIDomain}auth/authenticate/${method}?state=${encodeURIComponent(
    state,
  )}&redirect_url=${encodeURIComponent(`${authReturnUrl}${returnUrl}`)}`
}

/** Clears the session and redirects the user to Helseid to log out */
export const clearSession = ({ dialog = false, kollega = false } = {}) => {
  localStorage.clear()
  sessionStorage.clear()

  window.location.href = `${currentAPIDomain}auth/logout${
    dialog ? '?dialog' : ''
  }${kollega ? '?kollega' : ''}`
}

/** Context session object */
export type Session = {
  isSignedIn: boolean
  isLoading: boolean
  state?: SessionState | null
  setState: (state: SessionState) => void
}

/** State session object that is stored in SessionStorage */
export type SessionState = {
  state?: string
  first_name?: string
  last_name?: string
}

/** Information about the current session */
export const SessionContext = createContext<Session>({
  isLoading: true,
  isSignedIn: false,
  setState: (state: SessionState) => {},
})

export const SessionProvider = ({ children }: { children: ReactNode }) => {
  const { isLoading, isSignedIn, state, setState } = useSession()

  return (
    <SessionContext.Provider value={{ isLoading, isSignedIn, state, setState }}>
      {children}
    </SessionContext.Provider>
  )
}
