import classNames from 'classnames'
import { forwardRef, InputHTMLAttributes } from 'react'

import styles from './Radio.module.scss'

export type Props = InputHTMLAttributes<HTMLInputElement>

export const Radio = forwardRef<HTMLInputElement, Props>(({ className, style, ...props }, ref) => (
  <input ref={ref} className={classNames(styles.Radio)} style={style} type="radio" {...props} />
))
