.block {
  display: block;
}

// Modifiers

// Generate sizes
@for $i from 1 through 9 {
  .-top-#{$i} {
    margin-top: var(--space-#{$i});
  }
}

@for $i from 1 through 9 {
  .-right-#{$i} {
    margin-right: var(--space-#{$i});
  }
}
@for $i from 1 through 9 {
  .-bottom-#{$i} {
    margin-bottom: var(--space-#{$i});
  }
}
@for $i from 1 through 9 {
  .-left-#{$i} {
    margin-left: var(--space-#{$i});
  }
}
