@import "../scss/functions";

.Radio {
  // Visually hidden
  position: relative;
  padding: 0;
  border: 0;
  appearance: none;
  width: rem(1.5);
  height: rem(1.5);
  display: inline-block;
  cursor: pointer;
  flex: 0 0 auto;

  // Toggle
  &:checked::after {
    transform: scale(0.5);
  }
  &:checked::before {
    border-color: var(--color-primary);
    border-width: 2px;
  }

  // Set default focus style - visual aid
  &:focus-visible::before {
    box-shadow: 0px 0px 0px 2px var(--color-primary-outline);
  }

  &:before {
    content: '';
    background: white;
    border: var(--border);
    border-radius: 50%;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
  }

  &:after {
    content: '';
    display: block;
    background: var(--color-primary);
    border-radius: 50%;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    transform-origin: center;
    transform: scale(0);
    transition: transform 150ms ease-in-out;
  }
}