@import "../scss/functions";

.heading {
  font-style: normal;
  font-weight: 600;
  font-size: rem(1);
  line-height: 18px;
  color: var(--color-text-primary);
  margin: 0;
}

.-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.-secondary {
  color: var(--color-text-alt-primary);
}

.-size-100 {
  font-size: rem(0.65);
  text-transform: uppercase;
}

.-size-200 {
  font-size: rem(0.8);
}

.-size-300 {
  font-size: rem(1.0);
}

.-size-400 {
  font-size: rem(1.2);
}
.-size-500 {
  font-size: rem(1.4);
}
.-size-600 {
  font-size: rem(1.8);
  line-height: rem(2);
}

.-size-700 {
  font-size: rem(2);
  line-height: 1;
  font-weight: 700;

  @media (min-width: 600px) {
    font-size: rem(3);
  }
}
.-size-800 {
  font-size: rem(2.5);
}

.-size-900 {
  font-size: rem(3);
}

.-no-margin {
  margin: 0;
}
