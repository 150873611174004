@import "../scss/functions";

// Faux checkbox
.Checkbox {
  // Visually hidden
  position: relative;
  appearance: none;
  padding: 0;
  border: 0;
  width: rem(1.5);
  height: rem(1.5);
  display: inline-block;
  cursor: pointer;

  // Toggle
  &:checked::after {
    transform: scale(0.9);
  }
  &:checked::before {
    border-color: var(--color-text-primary);
  }

  // Set default focus style - visual aid
  &:focus::before {
    border-color: var(--color-text-primary);
  }

  &:before {
    content: "";
    background: white;
    border: var(--border);
    border-radius: var(--border-radius);
    border-width: 2px;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &:after {
    content: "✔";
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--color-text-primary);
    position: absolute;
    transform-origin: center center;
    transition: transform 0.25s ease-in-out;
    transform: scale(0);
    top: 0px;
    left: 0px;
  }
}