import { useHistory } from 'react-router-dom'
import { Flow, Button } from 'ui'

export default function FollowUpThankYou() {
  const history = useHistory()

  return (
    <>
      <Flow>
        <h1>Takk!</h1>

        <p>
          Takk for at du ønsker å bli blodgiver. Vi tar kontakt med deg i løpet
          av noen dager.
        </p>

        <Button
          onClick={() => {
            history.push('/')
          }}
        >
          Avslutt
        </Button>
      </Flow>
    </>
  )
}
