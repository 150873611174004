.button {
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: transform 150ms var(--transition-ease-out-expo),
    opacity 100ms ease-in-out;
  padding: var(--space-unit) var(--space-7);
  user-select: none; // Disable annoying text selection instead of click - on touch screens / iOS

  &:active {
    transform: translateY(2px);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none;
  }
}

// Additional styles when used as a link
.linkButton {
  text-decoration: none;
  text-align: center;
}

// Modifiers

.-primary {
  background: var(--color-primary);
  border-radius: var(--border-radius--huge);
  color: var(--color-white);

  &:hover:not([disabled]) {
    background: var(--color-primary-hover);
  }

  &:focus-visible {
    box-shadow: inset 0 0 0 2px var(--color-primary-outline), inset 0 0 0 4px var(--color-white);
  }
}

.-secondary {
  background: var(--color-button-secondary);
  border-radius: var(--border-radius--huge);
  color: var(--color-text-primary);

  &:hover:not([disabled]) {
    background: var(--color-secondary-hover);
  }

  &:focus-visible {
    box-shadow: inset 0 0 0 2px var(--color-secondary), inset 0 0 0 4px var(--color-text-primary);
  }
}

.-small {
  background: var(--color-button-secondary);
  border-radius: var(--border-radius);
  color: var(--color-text-alt-primary);
  padding: var(--space-4) var(--space-5);

  &:hover:not([disabled]) {
    background: var(--color-secondary-hover);
  }
}

.-transparent {
  background: transparent;
  color: var(--color-primary);

  &:hover:not([disabled]) {
    background: transparent;
  }
}

.-full-width {
  display: block;
  width: 100%;
}

.-margin-right {
  margin-right: var(--space-4);
}

.-circular {
  border-radius: 50%;
  text-align: center;
}

.-danger {
  background-color: var(--color-red);
  color: white;

  &:hover:not([disabled]) {
    background-color: var(--color-red);
  }
}
