import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'

declare const process: {
  env: { [index: string]: string }
}

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_GRAPHQL_GATEWAY}/graphql`,
  cache: new InMemoryCache(),
  credentials: 'include',
  defaultOptions: {
    mutate: { errorPolicy: 'all' },
  },
})

type PropTypes = {
  children: React.ReactNode
}

export function ApolloWrapper({ children }: PropTypes) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
