@import "../scss/functions";

.wrap {
  margin-bottom: var(--space-3);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.label {
  display: inline-block;
  padding-left: rem(0.8);
  position: relative;
  color: var(--color-blue--dark);
}

.error {
  margin-top: var(--space-2);
  color: var(--color-red);
}

.description {
  color: var(--color-gray);
  margin-top: var(--space-2);
  font-size: 90%;
}
