import { format } from 'date-fns-tz'
import nb from 'date-fns/locale/nb'

function uppercaseFirst(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

// Custom format
export const formatDateCustom = (
  date: string,
  dateFormat = 'd. MMMM yyyy',
  fallback = '—',
) => {
  if (!date) {
    return fallback
  }

  return format(new Date(date), dateFormat, {
    locale: nb,
    timeZone: 'Europe/Oslo',
  })
}

// Ex: "Mandag 21. april 2022"
export const formatDateWithWeekday = (date: string) => {
  return uppercaseFirst(formatDateCustom(date, 'EEEE dd. MMMM yyyy'))
}

// Ex: "12:45"
export const formatTime = (date: string) => {
  return formatDateCustom(date, 'kk:mm')
}

// Ex: "Mandag 21. april 2022, kl 12:45"
export const formatDateWithWeekdayAndTime = (date: string) => {
  return `${formatDateWithWeekday(date)}, kl ${formatTime(date)}`
}
