.box {
  will-change: auto;
}

// Modifiers
.-animation-down {
  animation: animationDown 500ms cubic-bezier(0.165, 0.84, 0.44, 1) both;
}

@keyframes animationDown {
  from {
    opacity: 0;
    transform: translateY(-32px);
  }
}
