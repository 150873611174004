import { useHistory } from 'react-router-dom'
import { Button, Flow, ArrowIcon, Heading } from 'ui'

export default function Welcome() {
  const history = useHistory()
  return (
    <>
      <Flow>
        <Heading size={600}>Kan jeg bli blodgiver?</Heading>
        <p>
          For å bli blodgiver må du være mellom 18-65 år, ha norsk personnummer
          og beherske norsk eller engelsk skriftlig og muntlig.
        </p>
        <p>Å gi blod skal være trygt både for giver og mottaker av blod.</p>
        <p>
          Det er også regler vedrørende helse og andre forhold som kan medføre
          smitterisiko, som kan ekskludere deg fra å gi blod.
        </p>

        <Button
          onClick={() => {
            history.push('/selvtest')
          }}
        >
          Start selvtest &nbsp; <ArrowIcon />
        </Button>
      </Flow>
    </>
  )
}
