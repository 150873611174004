import { Props as IconProps } from './Icon'

export type Props = IconProps

export const ArrowSmallIcon = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 13 10"
    width="13"
    height="10"
    fill="none"
    {...props}
  >
    <path
      d="M7.62324 9.79644C7.42128 9.79644 7.22811 9.73497 7.04371 9.61204C6.85931 9.49789 6.70565 9.34862 6.58272 9.16422C6.46857 8.97983 6.41149 8.79543 6.41149 8.61104C6.41149 8.4003 6.4993 8.17639 6.67492 7.93931C6.85053 7.70223 7.05688 7.46954 7.29396 7.24124C7.53982 7.00416 7.75495 6.8022 7.93935 6.63536H2.49966C1.99916 6.63536 1.64354 6.59585 1.4328 6.51682C1.22206 6.4378 1.05523 6.30609 0.932297 6.12169C0.809366 5.94607 0.7479 5.70021 0.7479 5.3841C0.7479 5.068 0.804975 4.82214 0.919125 4.64652C1.04206 4.47091 1.20889 4.33919 1.41963 4.25139C1.63037 4.17236 1.99038 4.13285 2.49966 4.13285H7.93935L7.29396 3.5138C6.93395 3.16257 6.69687 2.89476 6.58272 2.71036C6.46857 2.51718 6.41149 2.33279 6.41149 2.15717C6.41149 1.97278 6.46857 1.78838 6.58272 1.60399C6.69687 1.41959 6.84614 1.27032 7.03054 1.15617C7.22371 1.03324 7.42128 0.97177 7.62324 0.97177C7.79886 0.97177 7.98764 1.02885 8.1896 1.143C8.39156 1.24836 8.62864 1.43276 8.90084 1.69618L12.6941 5.3841L8.97987 8.993C8.66376 9.29155 8.40473 9.49789 8.20277 9.61204C8.00081 9.73497 7.80764 9.79644 7.62324 9.79644Z"
      fill="currentColor"
    />
  </svg>
)
