import { Section, LinkButton, Flow, Alert } from 'ui'

type Props = {
  errorMessage: string
  errorInfo: string
}

export default function ErrorBoundaryPage({ errorMessage, errorInfo }: Props) {
  return (
    <>
      <Section verticalPadding="medium">
        <Flow>
          <h1>Feil!</h1>
          <Alert variant="error">Beklager, noe gikk galt.</Alert>
          <LinkButton href="/">Gå tilbake til forsiden</LinkButton>
          <br />
          <br />
          <br />

          <Alert variant="info">
            <Flow>
              <h2 className="h4">Feilmelding:</h2>
              <code>{errorMessage}</code>
              <br />
              <code>{errorInfo}</code>
            </Flow>
          </Alert>
        </Flow>
      </Section>
    </>
  )
}
