import LoginButton from 'components/LoginButton'
import { Flow, Heading } from 'ui'

export default function MyPage() {
  return (
    <>
      <Flow>
        <Heading size={600}>Min side</Heading>
        <p>
          Logg deg inn for å endre eller avbestille en avtale hos Blodbanken
          Oslo.
        </p>
        <LoginButton myPage />
      </Flow>
    </>
  )
}
