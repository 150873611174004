.section {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--gutter);
  padding-right: var(--gutter);
}

// Modifiers

.-full {
  width: 100%;
}

.-large {
  max-width: 1150px;
}

.-medium {
  max-width: 800px;
}

.-small {
  max-width: 600px;
}

.-center-content {
  text-align: center;
}

.-vertical-padding {
  &-tiny {
    padding-top: var(--space-4);
    padding-bottom: var(--space-4);
  }
  &-small {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }
  &-medium {
    padding-top: var(--space-6);
    padding-bottom: var(--space-6);
  }
  &-large {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }
}

.-background {
  &-light {
    background-color: var(--color-blue--light);
  }
  &-white {
    background-color: white;
  }
}

.-no-gutter {
  padding-left: 0;
  padding-right: 0;
}
