.divider {
  display: block;
  height: 1px;
  border: 0;
  border-radius: var(--border-radius--huge);
  border-color: var(--color-blue);
  border-style: solid;
  border-width: 1px;
  opacity: 0.5;
}

// Modifiers
.-height-1 {
  height: 1px;
}

.-height-2 {
  height: 2px;
}

.-height-3 {
  height: 3px;
}

.-orientation-vertical {
  border-left-width: 1px;
  height: 100%;
}

.-orientation-horizontal {
  border-bottom-width: 1px;
  height: 0px;
}
