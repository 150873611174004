.portal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 500;
}

.overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 500;
  opacity: 1;
  animation: fade-in 450ms var(--transition-ease-out-quart) both;

  &--exit :local { 
    animation: fade-out 450ms var(--transition-ease-out-quart) both;
  }

  &--fade-background {
    background: rgba(238, 245, 250, 0.6);
    background: var(--color-background-faded, rgba(238, 245, 250, 0.6));
  }

  &--click-through {
    pointer-events: none;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
}

@keyframes fade-out {
  to {
    opacity: 0;
  }
}
