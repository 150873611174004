import { Button, CloseIcon, LinkButton, Row, Section } from 'ui'

import styles from './Header.module.scss'
import useSystemStatusMessage from 'hooks/useSystemStatusMessage'

export type Props = {
  onClickCancel?: () => void
  isSignedIn: boolean
  logout: () => void
}

export default function Header({ onClickCancel, isSignedIn, logout }: Props) {
  const { tracerSystemMessage } = useSystemStatusMessage()
  return (
    <>
      <Section width="full" background="white" noGutter>
        <Row center style={{ justifyContent: 'space-between' }}>
          <Row center>
            <div className={styles.logo}>
              <a href="/" title="Gå tilbake til startsiden">
                Remin
              </a>
            </div>
            <div className={styles.title}>Blodbanken Oslo</div>
          </Row>

          <Row>
            <LinkButton
              variant="small"
              href="/min-side"
              style={{ borderRadius: '30px', marginRight: '25px' }}
            >
              Min side
            </LinkButton>

            {isSignedIn && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <button onClick={() => logout()}>Logg ut</button>
                <Button circular variant="small" className={styles.button} onClick={() => logout()}>
                  <span style={{ position: 'relative' }}>
                    <CloseIcon />
                  </span>
                </Button>
              </div>
            )}
          </Row>
        </Row>
        {tracerSystemMessage && <div className={styles.banner}>⚠️ {tracerSystemMessage}</div>}
      </Section>
    </>
  )
}
