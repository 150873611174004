import classNames from 'classnames'
import { CSSProperties } from 'react'

import styles from './Spinner.module.scss'

export type Props = {
  width?: number
  style?: CSSProperties
  className?: string
}

export const Spinner = ({ width = 50, className, style }: Props) => {
  const strokeWidth = Math.max(1, Math.round(width / 10))
  const dash = width * 3
  const c = width / 2
  const radius = width / 2 - strokeWidth
  /* eslint-disable */
  const path = `
	  M ${c - radius}, ${c}
	  a ${radius},${radius} 0 1,0 ${radius * 2},0
	  a ${radius},${radius} 0 1,0 -${radius * 2},0
	`
  /* eslint-enable */

  return (
    <span
      style={{
        width: `${width}px`,
        height: `${width}px`,
        ...style,
      }}
      className={classNames(styles.spinner, className)}
    >
      <svg
        className={styles.svg}
        viewBox={`0 0 ${width} ${width}`}
        // Aidn CSS overrides any svg without a fill attribute
        fill="none"
        style={{
          width: `${width}px`,
          height: `${width}px`,
          strokeWidth,
        }}
      >
        <path className={styles['line-muted']} d={path} />
        <path className={styles.line} d={path} style={{ strokeDasharray: dash }} />
      </svg>
    </span>
  )
}
