import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Flow, Alert, Input, Button, Block } from 'ui'
import { postWorkItem } from 'requests/requests'

export function ResultFollowUp() {
  const history = useHistory()

  const [errorMessage, setErrorMessage] = useState<any>(null)

  const {
    register: registerFollowUp,
    handleSubmit: handleSubmitFollowUp,
    formState: { errors: errorsFollowUp, isSubmitting },
  } = useForm()

  const onClickSubmit = async (data: any) => {
    const { phone, firstName, lastName } = data
    const response = await postWorkItem({ phone, firstName, lastName })

    if (response?.errors != null) {
      setErrorMessage(response.message)
      return
    }

    history.push('/selvtest/takk')
  }

  return (
    <Alert variant="info">
      <Flow space="normal">
        <p>
          Basert på dine svar så vil vi gjerne stille deg noen ekstra spørsmål.
        </p>
        <p>
          Ring oss på telefon{' '}
          <a
            href="tel:22118900"
            className="link"
            style={{ whiteSpace: 'nowrap' }}
          >
            22 11 89 00
          </a>{' '}
          mellom kl 07:45 og 15:00 på hverdager.
        </p>
        <p>Eller legg igjen ditt navn og mobilnummer, så ringer vi deg:</p>
      </Flow>
      <Block top="6">
        <form onSubmit={handleSubmitFollowUp(onClickSubmit)}>
          <Flow space="normal">
            <Input
              type="text"
              name="firstName"
              label="Fornavn"
              register={registerFollowUp('firstName', {
                required: 'Skriv inn ditt fornavn',
              })}
              errorMessage={errorsFollowUp?.firstName?.message}
            />

            <Input
              type="text"
              name="lastName"
              label="Etternavn"
              register={registerFollowUp('lastName', {
                required: 'Skriv inn ditt etternavn',
              })}
              errorMessage={errorsFollowUp?.firstName?.message}
            />

            <Input
              type="tel"
              name="phone"
              label="Mobilnummer"
              description="8 siffer, for eksempel: 91234567"
              maxLength={8}
              register={registerFollowUp('phone', {
                required: 'Skriv inn ditt mobilnummer, 8 siffer',
                minLength: {
                  value: 8,
                  message: 'Skriv inn ditt mobilnummer, 8 siffer',
                },
              })}
              errorMessage={errorsFollowUp?.phone?.message}
            />

            {errorMessage != null && (
              <Alert variant="error">
                <p>
                  Noe gikk galt. Feilmelding:
                  <br />
                  <code>{errorMessage}</code>
                </p>
              </Alert>
            )}

            <Button
              type="submit"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Send
            </Button>
          </Flow>
        </form>
      </Block>
    </Alert>
  )
}
