.inputIconWrapper {
  background-color: white;
  border-radius: var(--border-radius);
  border: var(--border);
  padding: 0;
  max-width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: border-color 250ms, outline 250ms, box-shadow 250ms;

  // Make sure the outline is not cut-off in overflow: hidden container
  margin: 1px;
}

.isDisabled {
  background-color: rgba(255, 255, 255, 0.5);

}

.isFocused {
  outline: 0px solid var(--color-border-active);
  box-shadow: 0px 0px 0px 1px var(--color-border-active-alt);
  border-color: var(--color-border-active);
}

.input {
  font-size: var(--font-size-enlarged);
  background-color: transparent;
  max-width: 100%;
  width: 100%;
  padding: var(--space-3) var(--space-4);
  // iOS - fix width on date fields
  -webkit-appearance: textfield;
  border: none;
  outline: none;
  line-height: 20px;

  &:focus {
    // Prevent override by consumer
    outline: none;
  }

  // Hack - iOS it adds a shadow DOM wrap around the value :(
  &::-webkit-date-and-time-value {
    text-align: left;
    height: 27px; // Beware - change this if input changes height (based on font-size)
    width: 100%;
    display: block;
  }

  &:placeholder-shown {
    text-overflow: ellipsis;
  }
}

// Modifiers
.-full {
  width: calc(100% - 2px);
}

.-large {
  width: 36ch;
}

.-medium {
  width: 26ch;
}

.-small {
  width: 10ch;
}

.-fullWidthMobile {
  @media (max-width: 480px) {
    display: block;
    width: 100%;
  }
}

.-error {
  border-color: var(--color-red);
}

.error {
  margin-top: var(--space-2);
  color: var(--color-red);
}

.description {
  color: var(--color-gray);
  margin-top: var(--space-2);
  font-size: 90%;
}

.prefix,
.postix,
.postfixIcon,
.prefixIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.prefixIconWrapper+.input,
.postfixIconWrapper+.input {
  padding: var(--space-3) 0;
}

.prefixIcon,
.postfixIcon {
  height: 16px;
  width: 16px;
  opacity: 0.75;
  color: var(--color-primary);
}

.prefixIconWrapper,
.postfixIconWrapper {
  margin: 4px 6px;
  flex: 1 1 auto;
  display: flex;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
}

.isFocused>.prefixIcon,
.isFocused>.postfixIcon {
  opacity: 1;
}

.postfixClickable,
.prefixClickable {
  border-radius: 4px;

  // Adjust according to lineheight
  min-width: 28px;
}

.postfixClickable:hover,
.prefixClickable:hover {
  cursor: pointer;
  background-color: var(--color-alt-background);
}

.prefixIconWrapper {
  margin-left: 8px;
}

.postfixIconWrapper {
  margin-right: 8px;
}

.postfixClickable {
  margin-right: 4px;
}

.prefixClickable {
  margin-left: 4px;
}