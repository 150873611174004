// @ts-nocheck
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Flow, Button, Spinner, Alert, Block, Section, ChevronIcon } from 'ui'
import useFetch from 'hooks/useFetch'
import { getAvailabilityUrl } from 'requests/requests'
import { formatDateWithWeekday, formatTime } from 'utils/formatDate'

type Slot = {
  id: string
  /* True if it's not possible to book a slot even when there are slots available */
  closed: boolean
  start_time: string
  end_time: string
  capacity: {
    total: number
    available: number
    /** True if no more slots are available for booking */
    full: boolean
  }
}

type GetAvailability = [
  {
    id: number
    title: string
    location: {
      address: string[]
    }
    dates: Array<{
      date: string
      slots: Slot[]
    }>
  },
]

type slotInfo = {
  slotId: string
  location: string
  date: string
  startTime: string
  address: string
}

export default function BookingLocation() {
  const history = useHistory()

  const { data, error, isLoading } = useFetch<GetAvailability>(
    getAvailabilityUrl(),
  )

  const [selectedLocation, setSelectedLocation] = useState<number[]>([])

  const toggleLocation = (id: number) => {
    setSelectedLocation([...selectedLocation, id])
  }

  const handleClick = ({
    slotId,
    location,
    date,
    startTime,
    address,
  }: slotInfo) => {
    const readableDate = `${formatDateWithWeekday(date)}, kl ${formatTime(
      startTime,
    )}`

    // Simple way to store data between routes / pages
    sessionStorage.setItem(
      'booking',
      JSON.stringify({
        slotId,
        location,
        readableDate,
        address,
      }),
    )
    history.push('/booking/bekreft')
  }

  // Remove all dates without slots
  const preparedData = data?.map((station) => ({
    ...station,
    dates: station.dates.reduce((total, date) => {
      const hasSlot = date?.slots?.some(
        ({ closed, capacity }) => !closed && !capacity.full,
      )
      if (!hasSlot) {
        return total
      }
      return [...total, date]
    }, []),
  })) as GetAvailability

  return (
    <>
      <Flow>
        <h1>Velg ønsket sted</h1>

        {isLoading && <Spinner />}

        {error && (
          <Alert>
            Noe gikk galt. Feilmelding:
            <br />
            <br />
            <code>{error?.message}</code>
          </Alert>
        )}

        {data == null ||
          (data?.length < 1 && <p>Ingen steder tilgjengelig.</p>)}

        {preparedData?.map(({ id, title, location, dates }) => (
          <Section background="white" verticalPadding="medium" key={id}>
            <Block bottom="6">
              <Block bottom="3">
                <h2>{title}</h2>
              </Block>
              <p>{location?.address?.join(', ')}</p>
            </Block>

            {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
            {!selectedLocation.includes(id) && (
              <Button
                variant="secondary"
                onClick={() => {
                  toggleLocation(id)
                }}
              >
                Vis ledige tider
                <ChevronIcon
                  style={{
                    transform: 'rotate(90deg)',
                    margin: '0 -16px 0 16px',
                  }}
                />
              </Button>
            )}

            {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
            {selectedLocation.includes(id) && (
              <>
                {dates == null ||
                  (dates?.length < 1 && (
                    <p>Ingen tilgjengelige tidspunkter.</p>
                  ))}

                {dates?.map(({ date, slots }) => (
                  <Block key={date} bottom="6">
                    <Block bottom="3">
                      <h3 className="h4">{formatDateWithWeekday(date)}</h3>
                    </Block>

                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      {slots
                        ?.filter(
                          ({ closed, capacity }) => !closed && !capacity.full,
                        )
                        ?.map(({ id, start_time: startTime }) => (
                          <Button
                            key={id}
                            variant="small"
                            style={{ width: '100px', margin: '6px' }}
                            onClick={() => {
                              handleClick({
                                slotId: id,
                                location: title,
                                address: location?.address?.join(', '),
                                date,
                                startTime,
                              })
                            }}
                          >
                            {formatTime(startTime)}
                          </Button>
                        ))}
                    </div>
                  </Block>
                ))}
              </>
            )}
          </Section>
        ))}
      </Flow>
    </>
  )
}
