import useSession from 'hooks/useSession'
import { Button } from 'ui'

export default function LogoutButton() {
  const { logout } = useSession()

  return (
    <>
      <Button onClick={logout}>Avslutt og logg ut</Button>
    </>
  )
}
