type StorageType = 'localStorage' | 'sessionStorage'

const storage = (type: StorageType) =>
  type === 'localStorage' ? localStorage : sessionStorage

/**
 * Get an item from storage
 *
 * @param key Key to get
 * @param storageType Which storage to get it from
 * @returns The value from localstorage, or undefined if not found
 */
export function getItem<T = string>(
  key: string,
  storageType: StorageType = 'sessionStorage',
) {
  try {
    const value = storage(storageType).getItem(key)

    if (!value) {
      return undefined
    }

    return value as unknown as T
  } catch (e) {
    console.log(e)
  }

  return undefined
}

/**
 * Get a value from localstorage
 *
 * @param key Key to get
 * @param storageType Which storage to get it from
 * @returns The value from localstorage, or undefined if not found
 */
export function getJSON<T = any>(
  key: string,
  storageType: StorageType = 'sessionStorage',
): T | undefined {
  try {
    const value = getItem(key, storageType)

    if (value === undefined) {
      return value
    }

    return JSON.parse(value) as T
  } catch (e) {
    console.log(e)
  }

  return undefined
}

export function removeItem(
  key: string,
  storageType: StorageType = 'sessionStorage',
) {
  storage(storageType).removeItem(key)
}

export function setItem(
  key: string,
  value: any,
  storageType: StorageType = 'sessionStorage',
) {
  if (!key) {
    return
  }

  if (value === undefined) {
    return removeItem(key, storageType)
  }

  storage(storageType).setItem(key, value)
}

export function setJSON(
  key: string,
  value: any,
  storageType: StorageType = 'sessionStorage',
) {
  try {
    const json = JSON.stringify(value)
    setItem(key, json, storageType)
  } catch (e) {
    console.log(e)
  }
}

export function clear(storageType: StorageType = 'sessionStorage') {
  storage(storageType).clear()
}
