import { Flow, Alert } from 'ui'

export function ResultDecline() {
  return (
    <Alert>
      <Flow space="normal">
        <p>Basert på dine svar så kan du dessverre ikke bli blodgiver. </p>
        <p>
          Du kan lese mer om kriteriene hos{' '}
          <a
            href="https://www.blodbanken-oslo.no/index.php?action=showtopic&topic=TgZakLJB"
            className="link"
          >
            Blodbanken Oslo
          </a>
          .
        </p>
      </Flow>
    </Alert>
  )
}
