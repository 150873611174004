.row {
  display: flex;
  flex-direction: row;
}

.-center {
  align-items: center;
}

.-expand {
  flex-grow: 1;
}

.-col-on-phone {
  @media (max-width: 800px) {
    flex-direction: column;

    .-row-reverse {
      flex-direction: column-reverse;
    }
  }
}

.-row-reverse {
  flex-direction: row-reverse;
}

.col {
  display: flex;
  flex-direction: column;
}

.-col-reverse {
  flex-direction: column-reverse;
}
