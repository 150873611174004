import useSWR from 'swr'
import axios from 'axios'

const getSanityUrl = (query: string) =>
  `https://xadkzvxg.apicdn.sanity.io/v1/data/query/production?query=${query}`

export type StatusMessage = {
  _createdAt: string // 2021-07-01T10:39:23Z
  _id: string
  _rev: string
  _type: 'systemStatus'
  _updatedAt: string // 2021-07-01T10:48:25Z
  booking: string | undefined
  patient: string | undefined
  tracing: string | undefined
  vaccination: string | undefined
}

export type SanityResponse<T> = {
  ms: number
  query: string
  result: T[]
}

const getStatusMessageFromSanity = async () => {
  try {
    const response = await axios.get<SanityResponse<StatusMessage>>(
      getSanityUrl("*[_type=='systemStatus']"),
    )
    return response?.data
  } catch (e) {
    // Let's just ignore if we for some reason can't get the current status messages
    console.warn('Could not get status messages')
  }
}

/**
 * Returns an object containing current system status messages that can be
 * modified by support staff in Sanity
 */
export default function useSystemStatusMessage() {
  const { data } = useSWR('sanityStatusMessage', getStatusMessageFromSanity, {
    refreshInterval: 5 * 60 * 1000,
  })
  const result = data?.result?.[0]
  const applicationUnavailable =
    result?.tracing?.match(':down:') != null ||
    result?.booking?.match(':down:') != null ||
    result?.patient?.match(':down:') != null ||
    result?.vaccination?.match(':down:') != null
  return {
    tracerSystemMessage: result?.tracing?.replace(/:down:/gi, ''),
    patientSystemMessage: result?.patient?.replace(/:down:/gi, ''),
    bookingSystemMessage: result?.booking?.replace(/:down:/gi, ''),
    vaccinationSystemMessage: result?.vaccination?.replace(/:down:/gi, ''),
    applicationUnavailable,
  }
}
