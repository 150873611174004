import classNames from 'classnames'
import { forwardRef, InputHTMLAttributes, ReactChild, useCallback, useState } from 'react'

import { Row } from '../Layout'
import { Label } from '../Label'
import styles from './Input.module.scss'

type Props = {
  name: string
  label?: string
  placeholder?: string
  disabled?: boolean
  width?: 'full' | 'large' | 'medium' | 'small'
  fullWidthMobile?: boolean
  register?: any
  errorMessage?: string
  description?: string
  prefix?: ReactChild
  prefixIcon?: ReactChild
  postfix?: ReactChild
  postfixIcon?: ReactChild
  postfixIconOnClick?: () => void
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'width' | 'prefix'>

export const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      type = 'text',
      label,
      placeholder,
      disabled,
      width = 'medium',
      fullWidthMobile = true,
      register,
      errorMessage,
      description,
      className,
      prefix,
      prefixIcon,
      postfix,
      postfixIcon,
      postfixIconOnClick,
      ...rest
    }: Props,
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false)
    const inputClass = classNames(
      styles[`-${width}`],
      {
        [styles['-error']]: errorMessage,
        [styles['-fullWidthMobile']]: fullWidthMobile,
      },
      className,
    )

    const onFocus: React.FocusEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        setIsFocused(true)
        rest.onFocus?.(e)
      },
      // eslint-disable-next-line
      [rest.onFocus],
    )

    const onBlur: React.FocusEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        setIsFocused(false)
        rest.onBlur?.(e)
      },
      // eslint-disable-next-line
      [rest.onBlur],
    )

    return (
      <div>
        {label && <Label htmlFor={name}>{label}</Label>}
        <Row
          colOnPhone={false}
          className={classNames(
            inputClass,
            styles.inputIconWrapper,
            isFocused && styles.isFocused,
            disabled && styles.isDisabled,
          )}
        >
          {prefix != null && <div className={styles.prefix}>{prefix}</div>}
          {prefixIcon != null && (
            <div className={styles.prefixIconWrapper}>
              <div className={classNames(styles.prefix, styles.prefixIcon)}>{prefixIcon}</div>
            </div>
          )}
          <input
            className={styles.input}
            ref={ref}
            type={type}
            placeholder={placeholder}
            name={name}
            id={name}
            disabled={disabled}
            {...register}
            {...rest}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          {postfixIcon != null && (
            <div
              className={classNames(
                styles.postfixIconWrapper,
                postfixIconOnClick && styles.postfixClickable,
              )}
              onClick={postfixIconOnClick}
            >
              <div className={classNames(styles.postfix, styles.postfixIcon)}>{postfixIcon}</div>
            </div>
          )}
          {postfix != null && <div className={styles.postfix}>{postfix}</div>}
        </Row>

        {errorMessage && <div className={styles.error}>↑ {errorMessage}</div>}

        {description && <div className={styles.description}>{description}</div>}
      </div>
    )
  },
)
