import { Props as IconProps } from './Icon'

export type Props = IconProps

export const CopyIcon = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 0 24 27" width="24" height="24" {...props}>
    <path
      d="M10 9.09756V5H18V15.2439H14M10 9.09756V15.2439H14M10 9.09756H6V19H14V15.2439"
      stroke="currentColor"
      fill="transparent"
      strokeWidth="1.25"
    />
  </svg>
)
