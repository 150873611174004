import clearSessionData from '../lib/clearSessionData'
import useFetch from './useFetch'

const API_URL = process.env.REACT_APP_API_URL ?? ''

const logoutUrl = `${
  process.env.REACT_APP_GRAPHQL_GATEWAY ?? ''
}/auth/logout?redirectUrl=${encodeURIComponent(
  process.env.REACT_APP_URL ?? '',
)}`

export const clearSession = () => {
  clearSessionData()
  window.location.href = logoutUrl
}

export default function useSession() {
  const { isLoading, error, data } = useFetch(`${API_URL}me`)

  const logout = clearSession

  return {
    isLoading,
    error,
    me: data ?? null,
    isSignedIn: Boolean(data),
    logout,
    clearSession,
  }
}
