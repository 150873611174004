// Based on: https://usehooks-ts.com/react-hook/use-fetch

import getAuthRequestHeaders from 'lib/getAuthRequestHeaders'
import { useEffect, useReducer, useRef } from 'react'

type State<T> = {
  data?: T
  error?: Error
  isLoading: boolean
}

// discriminated union type
type Action<T> =
  | { type: 'fetched', payload: T }
  | { type: 'error', payload: Error }

function useFetch<T = unknown>(
  url?: string,
  options: RequestInit = { credentials: 'include' },
): State<T> {
  // Used to prevent state update if the component is unmounted
  const cancelRequest = useRef<boolean>(false)

  const initialState: State<T> = {
    error: undefined,
    data: undefined,
    isLoading: Boolean(url),
  }

  // Keep state logic separated
  const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
      case 'fetched':
        return {
          ...initialState,
          data: action.payload,
          isLoading: false,
        }
      case 'error':
        return {
          ...initialState,
          error: action.payload,
          isLoading: false,
        }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(fetchReducer, initialState)

  useEffect(() => {
    // Do nothing if the url is not given
    if (!url) return

    cancelRequest.current = false

    const fetchData = async () => {
      try {
        const response = await fetch(url, {
          ...options,
          headers: {
            ...getAuthRequestHeaders(),
            ...(options?.headers ?? {}),
          },
        })
        if (!response.ok) {
          const errorResponse = await response?.json()
          throw new Error(errorResponse?.message)
        }

        const data = (await response.json()) as T
        if (cancelRequest.current) return

        dispatch({ type: 'fetched', payload: data })
      } catch (error) {
        if (cancelRequest.current) return

        dispatch({ type: 'error', payload: error as Error })
      }
    }

    void fetchData()

    // Use the cleanup function for avoiding a possibly...
    // ...state update after the component was unmounted
    return () => {
      cancelRequest.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])

  return state
}

export default useFetch
