import { questions } from 'questions/questions'
import {
  ResultDefault,
  ResultDecline,
  ResultFollowUp,
  ResultAccept,
} from 'components/Selftest'

export default function calculateResult(answers: any, isSignedIn: boolean) {
  // They need to answer all questions first
  if (
    !questions.every(
      ({ key }) => answers[key] === 'true' || answers[key] === 'false',
    )
  ) {
    return <ResultDefault />
  }

  // Decline if they answer yes to a decline-question
  if (
    questions.some(({ key, decline }) => answers[key] === 'true' && decline)
  ) {
    return <ResultDecline />
  }

  // Follow up for other yes-questions
  if (questions.some(({ key }) => answers[key] === 'true')) {
    return <ResultFollowUp />
  }

  // All good - accept
  if (questions.every(({ key }) => answers[key] === 'false')) {
    return <ResultAccept isSignedIn={isSignedIn} />
  }

  // Generic
  return <ResultDefault />
}
