import classNames from 'classnames'

import { Fieldset } from '../Fieldset'
import { Legend } from '../Legend'
import { Block } from '../Block'
import styles from './RadioGroup.module.scss'
import { Radio } from '../Radio'

type Option = {
  value: string | boolean
  label: string
  disabled?: boolean
}

type Props = {
  name: string
  title?: string | JSX.Element
  options?: Option[]
  disabled?: boolean
  register?: any
  errorMessage?: string
  description?: string
}

export function RadioGroup({
  name,
  title,
  options,
  disabled,
  register,
  errorMessage,
  description,
  ...rest
}: Props) {
  return (
    <Fieldset>
      <Block bottom="3">
        <Legend>{title}</Legend>
      </Block>
      {options?.map(({ label, value }) => (
        <div key={value?.toString()}>
          <label className={styles.wrap}>
            <Radio
              name={name}
              id={value?.toString()}
              value={value?.toString()}
              disabled={disabled}
              {...register}
              {...rest}
            />
            <span
              className={classNames(styles.label, errorMessage != null && styles['radio-error'])}
            >
              {label}
            </span>
          </label>
        </div>
      ))}

      {errorMessage && <div className={styles.error}>↑ {errorMessage}</div>}

      {description && <div className={styles.description}>{description}</div>}
    </Fieldset>
  )
}
