.input {
  background-color: white;
  border-radius: var(--border-radius);
  border: var(--border);
  font-size: var(--font-size-enlarged);
  max-width: 100%;
  padding: var(--space-3) var(--space-4);
  box-sizing: border-box;
  display: block;
  transition: border-color 250ms, outline 250ms, box-shadow 250ms;

  // Make sure the outline is not cut-off in overflow: hidden container
  margin: 1px;

  // iOS - fix width on date fields
  -webkit-appearance: textfield;
  transition: border-color 250ms, box-shadow 250ms, outline 250ms;

  &:disabled {
    background-color: rgba(255, 255, 255, 0.5);
  }

  &:focus-visible {
    outline: 0px solid var(--color-border-active);
    box-shadow: 0px 0px 0px 1px var(--color-border-active-alt);
    border-color: var(--color-border-active);
  }

  // Hack - iOS it adds a shadow DOM wrap around the value :(
  &::-webkit-date-and-time-value {
    text-align: left;
    height: 27px; // Beware - change this if input changes height (based on font-size)
    width: 100%;
    display: block;
  }

  &:placeholder-shown {
    text-overflow: ellipsis;
  }
}

// Modifiers
.-full {
  display: block;
  width: calc(100% - 2px);
}

.-large {
  width: 36ch;
}

.-medium {
  width: 26ch;
}

.-small {
  width: 10ch;
}

.-fullWidthMobile {
  @media (max-width: 480px) {
    display: block;
    width: 100%;
  }
}

.-error {
  border-color: var(--color-red);
}

.error {
  margin-top: var(--space-2);
  color: var(--color-red);
}

.description {
  color: var(--color-gray);
  margin-top: var(--space-2);
  font-size: 90%;
}
