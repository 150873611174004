import { useGetAccessTokenQuery } from 'generated/graphql'
import { useEffect, createContext, useState, useRef } from 'react'
import { getJSON } from 'lib/storage'
import decode from 'jwt-decode'
import { clearSession } from 'hooks/useSession'

let token: string | undefined, reminAuthContext: string | undefined

export const setAccessToken = (accessToken: typeof token) =>
  (token = accessToken)

export const getAccessToken = () => {
  document.dispatchEvent(new Event('fetched-access-token'))
  return token
}
export const getAuthContext = () => reminAuthContext

const INACTIVITY_THRESHOLD = 1000 * 60 * 60

export const AccessTokenContext = createContext<{
  loading: boolean
  token?: string
}>({ loading: true, token })

type Props = {
  children: React.ReactNode
  authContext: string
}

export default function AccessTokenWrapper({
  children,
  authContext = 'tracing',
}: Props) {
  const [ready, setReady] = useState(false)
  const lastCall = useRef<Date>(new Date())
  const {
    loading,
    data: { accessToken } = {},
    refetch,
  } = useGetAccessTokenQuery()

  useEffect(() => {
    reminAuthContext = authContext
  }, [authContext])

  useEffect(() => {
    const eventHandler = () => {
      lastCall.current = new Date()
    }

    // keep track of when the token was last fetched
    document.addEventListener('fetched-access-token', eventHandler)

    return () =>
      document.removeEventListener('fetched-access-token', eventHandler)
  }, [])

  useEffect(() => {
    if (Boolean(loading)) {
      return
    }

    /**
     * If we have a session but no token was returned it means that the user
     * session has expired and the user should be kicked out
     */
    if (!Boolean(accessToken) && getJSON('session') !== undefined) {
      clearSession()
      return
    }

    const token = Boolean(accessToken) ? String(accessToken) : undefined
    setAccessToken(token)

    let timeout: NodeJS.Timeout

    // get the exp time from the JWT
    const exp =
      (token ? decode<{ exp: number }>(token)?.exp : undefined) ?? undefined

    /**
     * if we have an expiry time from the JWT that is in the future, set up a
     * timeout to trigger the refresh of the token sixty seconds before the JWT
     * expires. This way we'll be able to keep the user logged in by getting fresh
     * tokens in time
     */
    if (exp !== undefined && new Date(exp * 1000) > new Date()) {
      timeout = setTimeout(() => {
        // do not get a new token if the user has been inactive for too long
        if (
          new Date().getTime() - (lastCall.current?.getTime() ?? 0) >
          INACTIVITY_THRESHOLD
        ) {
          return
        }

        void refetch()
      }, (exp - 60) * 1000 - new Date().getTime())
    }

    setTimeout(() => setReady(true),0)

    return () => {
      clearTimeout(timeout)
    }
  }, [loading, accessToken, refetch])

  if (!ready) {
    return null
  }

  return (
    <AccessTokenContext.Provider
      value={{
        loading,
        token: Boolean(accessToken) ? String(accessToken) : undefined,
      }}
    >
      {children}
    </AccessTokenContext.Provider>
  )
}
