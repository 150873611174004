.label {
  display: block;
  margin-bottom: var(--space-2);
  line-height: 1.5em;
  font-weight: 600;
  color: var(--color-blue--dark);
}

.select {
  background-color: white;
  border: var(--border);
  border-radius: var(--border-radius);
  font-size: var(--font-size-enlarged);
  max-width: 100%;
  padding: var(--space-3) var(--space-4);

  // Background icon
  padding-right: var(--space-6);
  background-image: url("../../assets/icons/chevron.svg");
  background-repeat: no-repeat;
  background-position: center right var(--space-3);

  &:disabled {
    opacity: 0.5;
  }

  &:focus-visible {
    outline: 1px solid var(--color-blue--dark);
    border-color: var(--color-blue--dark);
  }
}

// Modifiers

.-full {
  display: block;
  width: 100%;
}

.-large {
  width: 36ch;
}

.-medium {
  width: 26ch;
}

.-small {
  width: 10ch;
}

.-fullWidthMobile {
  @media (max-width: 420px) {
    display: block;
    width: 100%;
  }
}

.-error {
  border-color: var(--color-red);
}

.error {
  margin-top: var(--space-2);
  color: var(--color-red);
}

.description {
  color: var(--color-gray);
  margin-top: var(--space-2);
  font-size: 90%;
}
